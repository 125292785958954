<template>
  <div class="eap-page">
    <div class="banner">
      <img src="./img/ae_banner.png" alt="">
    </div>
    <div class="boy-girl">
      <div class="left">
        <div class="aircraft">
          <img src="./img/ae_aircraft.png" alt="">
        </div>
        <div class="girl">
          <img src="./img/ae_woman.png" alt="">
        </div>
        <div class="boy">
          <img src="./img/ae_man.png" alt="">
        </div>
      </div>
      <div class="right">
        <div class="title">即将开始海外留学生活的你，是不是也在焦虑</div>
        <div class="left-quot">
          <img src="./img/ae_fuhao1.png" alt="">
        </div>
        <div class="con">
          听说海外留学挂科率很高，特别难毕业，我在国内应该做一些什么准备？
          <br>
          <br>
          <br>
          英语基础一般，马上要去国外读语言班，但是全英文的环境我拍自己不适<br>应，这该怎么办？
        </div>
        <div class="right-quot">
          <img src="./img/ae_fuhao2.png" alt="">
        </div>
        <div class="slogan">别担心，这一次，趴趴帮你赢在起跑线上！</div>
      </div>
    </div>
    <div class="section">
      <div class="title">重磅推出 学术英语课程</div>
      <div class="desc">
        <div class="item" v-for="item in section" :key="item.title">
          <div class="title">{{item.title}}</div>
          <div class="detail">{{item.detail}}</div>
          <img :src="item.img" alt="">
        </div>
      </div>
    </div>
    <div class="introduce-teacher">
      <div class="title">重磅师资介绍</div>
      <div class="teacher-pic">
        <img src="./img/ae_teacher.png" alt="">
      </div>
    </div>
    <div class="teach-content">
      <div class="title">教学内容</div>
      <div class="subtitle">
        词汇技巧及听说读写全面覆盖
      </div>
      <div class="content-list">
        <div class="item" v-for="item in teachContent" :key="item.title">
          <div class="title">{{item.title}}</div>
          <div class="con">{{item.con}}</div>
        </div>
      </div>

    </div>
    <div class="teaching-material">
      <div class="content">
        <div class="left">
          <div class="title">课程教材</div>
          <div class="subtitle">海外名校学术语言授课内容的权威参考教材</div>
          <div class="slogan">
            Headway Academic Skills :<br>
            Listening - Speaking & Reading - Writing
          </div>
        </div>
        <div class="right animated fadeInUp">
          <img src="./img/ae_pic.png" alt="">
        </div>
      </div>
    </div>
    <div class="past-course">
      <div class="content">
        <div class="left">
          <img src="./img/ae_pic2.png" alt="">
        </div>
        <div class="right">
          <div class="title">
            往期课程实景
          </div>
          <div class="desc">
            此次课程跟国内很多“填鸭式”的课程不同，课上会有
            <i>
              和海外课堂一样
              的小组讨论
            </i>

            ，每个单元的课程结束都会有相对应的听说读写的作业需要
            完成，老师上课会讲解。
          </div>
        </div>
      </div>
    </div>
    <div class="course-special">
      <div class="content">
        <div class="title">课程六大特色</div>
        <div class="special-list">
          <div class="item" v-for="item in specialList" :key="item.title">
            <img :src="item.img" alt="">
            <div class="title">{{item.title}}</div>
            <div class="desc" v-html="item.desc"></div>
          </div>
        </div>
      </div>
    </div>
    <FloatBar category="eap"></FloatBar>
    <FloatAds></FloatAds>

  </div>
</template>

<script>
  import FloatBar from '@/components/FloatBar/FloatBar.vue'
  import FloatAds from '@/components/FloatAds/FloatAds.vue'

  import specialPic1 from './img/ae_icons1.png'
  import specialPic2 from './img/ae_icons2.png'
  import specialPic3 from './img/ae_icons3.png'
  import specialPic4 from './img/ae_icons4.png'
  import specialPic5 from './img/ae_icons5.png'
  import specialPic6 from './img/ae_icons6.png'
  import section from './img/ae_part2_icons1.png'
  import section2 from './img/ae_part2_icons2.png'
  import section3 from './img/ae_part2_icons3.png'

  export default {
    name: 'Eap',
    data () {
      return {
        section: [
          {
            title: '还原海外模式',
            detail: '大咖教授、小班教学、视频互动、小组讨论、课后论文',
            img: section
          },
          {
            title: '建立学术思维,学术能力全面提升',
            detail: '学术词汇、学习技能、听说读写各项突破、学术词汇、学习技能、',
            img: section2
          },
          {
            title: '48小时的深入直播讲解',
            detail: '带你快速攻破语言难关，给你留学生活高起点！',
            img: section3
          }
        ],
        teachContent: [
          {
            title: 'Speaking',
            con: 'presentations / discussions / interviews /critical thinking / describing facts and figures'
          }, {
            title: 'Listening',
            con: 'listening for gist / note taking / critical thinking (evaluating)'
          }, {
            title: 'Reading',
            con: 'effective reading strategies / predicting content / avoiding plagiarism / finding evidence from multiple sources / distinguishing facts & opinions'
          }, {
            title: 'Writing',
            con: 'comparing & contrast essay / developing paragraphs / writing a persuasive article / writing an opinion essay'
          }, {
            title: 'Vocabulary',
            con: 'using dictionaries / academic words / collocations / compound nouns'
          }, {
            title: 'Study skills',
            con: 'research strategies / avoiding plagiarism'
          },
        ],
        specialList: [
          {
            title: '知名大咖教授',
            img: specialPic1,
            desc: '英籍前曼大教授<br>牛津本硕背景<br>30+年学术教学经验'
          },
          {
            title: '精华学术内容',
            img: specialPic2,
            desc: '48小时听说读写<br>词汇学术能力\<br>综合提升'
          },
          {
            title: '还原海外课堂',
            img: specialPic3,
            desc: '浸泡式小班高<br>互动视频教学<br>全真还原海外<br>顶尖名校学术课堂'
          },
          {
            title: '教授亲自批改',
            img: specialPic4,
            desc:'阶段性学术作业布置<br>附赠5篇ESSAY\<br>教授亲自批改反馈'
          },
          {
            title: '贴心课程回放',
            img: specialPic5,
            desc: '开课后60天<br>无限次回放<br>温故而知新'
          },
          {
            title: '专属课程助教',
            img: specialPic6,
            desc: '全程学习陪伴<br>为你的学习<br>保驾护航'
          }
        ]
      }
    },
    components: {
      FloatBar,
      FloatAds
    }
  }
</script>

<style lang="less">
  .eap-page {
    background: #fff;

    & > .banner {
      width: 100%;
      max-height: 400px;
      font-size: 0;

      & > img {
        width: 100%;
      }
    }

    & > .boy-girl {
      width: 1200px;
      margin: 0 auto;
      padding: 85px 0;
      display: flex;

      & > .left {
        width: 520px;
        height: 430px;
        position: relative;

        & > .aircraft {
          position: absolute;
          top: 0;
          left: 84px;

        }

        & > .girl {
          position: absolute;
          left: 0;
          top: 30px;
          animation: girl 3s linear infinite 1500ms;

        }

        & > .boy {
          position: absolute;
          right: 0;
          top: -50px;
          animation: boy 3s linear infinite;
        }
      }

      & > .right {
        width: 612px;
        margin-left: 66px;

        & > .title {
          font-size: 24px;
          color: #333;
          font-weight: bold;
          text-align: left;
        }

        & > .left-quot {
          margin-top: 30px;
          height: 56px;
          position: relative;
          &>img{
            position: absolute;
            left: -28px;
          }
        }

        & > .con {
          text-align: left;
          font-size: 18px;
          color: #555;
        }

        & > .right-quot {
          height: 56px;
          position: relative;
          &>img{
            position: absolute;
            right: -9px;
          }
        }

        & > .slogan {
          width: 526px;
          height: 57px;
          text-align: center;
          line-height: 57px;
          color: #fff;
          font-size: 26px;
          background: #C7B088;
        }
      }
    }

    & > .section {
      & > .title {
        margin-bottom: 40px;
      }

      & > .desc {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        justify-content: space-around;

        & > .item {
          width: 380px;
          height: 140px;
          border: 1px solid #e6e6e6;
          text-align: left;
          box-sizing: border-box;
          padding: 30px;
          position: relative;

          & > .title {
            font-size: 22px;
            color: #333;
            font-weight: bold;
            margin-bottom: 10px;
          }

          & > .detail {
            font-size: 16px;
            color: #555;
          }
          &>img{
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
    }

    & > .introduce-teacher {
      width: 100%;
      padding: 80px 0;

      & > .teacher-pic {
        width: 100%;
        padding-top: 40px;

        & > img {
          width: 100%;
        }
      }
    }

    & > .teach-content {
      padding-bottom: 80px;

      & > .subtitle {
        color: #555;
        font-size: 16px;
        padding-bottom: 40px;
      }

      & > .content-list {
        width: 1200px;
        margin: 0 auto;

        & > .item {
          width: 100%;
          display: flex;
          margin-bottom: 10px;

          & > .title {
            background: #C7B088;
            color: #fff;
            font-size: 18px;
            padding: 0 10px;
          }

          & > .con {
            padding-left: 20px;
            color: #555;
            font-size: 16px;
            background: #F9F9F9;
          }

        }
      }
    }

    & > .teaching-material {
      width: 100%;
      background: #FAFCFD;
      padding: 80px 0;

      & > .content {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;

        & > .left {
          text-align: left;

          & > .subtitle {
            font-size: 16px;
            color: #555;
            margin-bottom: 60px;
          }

          & > .slogan {
            font-size: 24px;
            color: #555;
            font-style: italic;
            font-weight: bold;
          }
        }

        & > .right {
          font-size: 0;
          margin-left: 140px;
        }
      }
    }

    & > .past-course {
      width: 100%;
      background: #fff;
      height: 400px;
      box-sizing: border-box;
      padding: 90px 0;

      & > .content {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        & > .left {

        }

        & > .right {
          width: 600px;
          margin-left: 144px;
          text-align: left;

          & > .title {
            font-size: 30px;
            color: #333;
            font-weight: bold;
            margin-bottom: 40px;
          }

          & > .desc {
            font-size: 18px;
            color: #555;

          }
        }

      }
    }

    &>.course-special{
      width: 100%;
      height: 450px;
      background: #FAFCFD;
      padding:60px 0 80px;
      box-sizing: border-box;
      &>.content{
        width: 1200px;
        margin: 0 auto;

        &>.special-list{
          display: flex;
          margin-top: 38px;
          &>.item{
            width:180px;
            height: 240px;
            margin-right:20px;
            background: #fff;
            color: #565656;
            box-sizing: border-box;
            padding: 34px 0px;
            text-align: center;

            &>.title{
              font-size: 20px;
              margin: 22px 0 20px ;
            }
            &>.desc{
              font-size: 16px;
            }
          }
        }
      }
    }

    .title {
      font-size: 30px;
      color: #333;
      font-weight: bold;
    }
  }

  @keyframes boy{
    0%{
      top: -50px;
    }
    50%{
      top: -40px;
    }
    100%{
      top: -50px
    }
  }
  @keyframes girl {
    0%{
      top: 30px;
    }
    50%{
      top: 40px;
    }
    100%{
      top: 30px
    }
  }

</style>
